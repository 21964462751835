@import '../../../../../../styles/variables.less';

#subheader-policies-component {
  .gx-header-horizontal-top {
    padding: 16px 0;
    height: 136px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @border-color;
  }

  .custom-horizontal-pdd {
    padding-top: 30px;

    &.retract {
      padding-bottom: 20px;
    }
  }

  .selected-icons {
    display: flex;
  }

  .subheader-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .subheader-info {
    flex: 1;
    padding-right: 15px;
  }

  .custom-breadcrumbs {
    &.expand {
      margin-left: 155px;
    }

    &.retract {
      margin-left: 135px;
    }
  }

  .gx-link {
    color: @black-color !important;
  }

  .ant-breadcrumb-link {
    color: @text-color;

    a {
      color: @text-color !important;

      &:hover {
        border-bottom: 1px solid @text-color;
      }
    }
  }
  .ant-breadcrumb-separator {
    color: @text-color;
  }

  .subheader-name {
    color: @black-color;;
    font-weight: 900;
    font-size: 30px;
    margin: 0 0 2px;

    width: fit-content;
    word-break: break-word;
  }

  .subheader-last-update {
    font-size: 13px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
  }

  .subheader-update-profile {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;
    padding: 0px;

    border: 1px solid @border-color;
    border-radius: 50%;
    background: transparent;

    i {
      color: #ffffff;
      font-size: 21px;

      margin-left: 2px;
    }

    span {
      color: @white-color;
      margin-left: 5px;
    }

    &:hover,
    &:focus {
      border-color: @primary-color;
      background: rgba(@white-color, 0.05);
    }
  }
}

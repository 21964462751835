body {
  color: @black-color !important;

  a {
    color: @black-color;
  }
}

::-webkit-scrollbar {
  width: 4px;
  background:  @light-dark-color;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  width: 4px;
  background: @dark-gray-color;
  border-radius: 3px;
}
.rc-virtual-list-scrollbar {
  width: 4px !important;
  background:  @light-dark-color !important;
  border-radius: 2px !important;
}
.rc-virtual-list-scrollbar-thumb {
  width: 4px !important;
  background: @dark-gray-color !important;
  border-radius: 2px !important;
}

#page-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-custom-primary {
  font-size: 1rem;
  font-weight: 700;
  height: 40px;
  color: @white-color !important;
  background-color: @primary-color;
  text-shadow: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    background-color: @secondary-color;
    color: @white-color;
  }

  svg {
    color: @white-color;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.btn-custom-secondary {
  font-size: 1rem;
  font-weight: 500;
  color: @primary-color;
  border-color: @primary-color;
  background-color: transparent;
  text-shadow: none;
  padding: 0 16px !important;

  &:hover,
  &:active,
  &:focus {
    background-color: @primary-color !important;
    color: @white-color !important;
  }
}

.custom-search-input,
.custom-input {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid @gray-light-color-1;
  box-shadow: none !important;
  padding-top: 0;
  padding-bottom: 0;

  input {
    background: transparent;
    border-radius: 0;
    height: 2.1rem;
    font-size: 1rem;
    margin-left: 16px;
  }

  i {
    font-size: 1rem;
    color: @text-color;
  }

  &:hover {
    border-bottom-color: @gray-light-color-1;
  }

  &:focus-within {
    border-bottom-color: @primary-color;
  }

  .ant-input-prefix {
    margin-right: 0.6rem;

    svg{
      color: @black-color;
      width: 24px;
      height: 24px;
    }
  }
}

.btn-more-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: @light-primary-color;
    .custom-caf-icon {
      color: @primary-color;
    }
  }
}

.select-icons {
  .rc-virtual-list-holder-inner {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
  }
  .ant-select-dropdown, #icon_list, .rc-virtual-list {
    width: 237px !important;
  }
}

.ant-select-arrow {
  svg {
    color: @gray-color;
    width: 24px;
    height: 24px;
  }
}

.ant-table-cell {
  a {
    display: flex;
    align-items: center;
  }
}

.ant-checkbox-disabled {
  span {
    background-color: @gray-color !important;
  }
}

.ant-checkbox-inner::after {
  left: 25% !important;
}

.ant-select-clear{
  right: 35px;
  background-color: transparent !important;
  svg {
    color: @gray-color !important;
  }
}

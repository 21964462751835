//global style variables
@dark-theme-border-color: @dark-gray-color;
@dark-theme-text-color: @white-color;

/*Base Styles*/
body.dark-theme {
  background: @dark-color !important;
  color: @dark-theme-text-color;
}

.dark-theme {
  #login-component {
    background-color: @black-color !important;
    span {
      color: @dark-theme-text-color !important;
    }
  }

  #header-component {
    header {
      border-bottom: 1px solid @light-dark-color;

      .nav-link.active {
        border-bottom: 3px solid rgba(@dark-theme-border-color, 40%);
      }

      a {
         color: @dark-theme-text-color !important;
      }
    }
  }

  #subheader-policies-component {
    .gx-header-horizontal-top {
      background-color: @black-color !important;
      border-bottom: 1px solid @light-dark-color;
    }

    .ant-breadcrumb-link, .ant-breadcrumb-separator {
      color: @dark-theme-text-color !important;
      a {
        color: @dark-theme-text-color !important;
      }
    }
  }

  #select-policies{
    .custom-collapse {
      background-color: @light-dark-color !important;
      border-color: @light-dark-color !important;

      .header-text {
        color: rgba(@white-color, 24%) !important;

        svg {
          color:rgba(@white-color, 24%)!important;
        }
      }

      .ant-collapse-item-active {
        .header-text, .ant-col:not(.col-read-tooltip) {
          color: @dark-theme-text-color !important;

          svg {
            color: @dark-theme-text-color !important;
          }
        }
      }

      .col-read-tooltip {
        svg {
          color: @primary-color !important;
        }
      }
    }
  }

  .user-dropdown .ant-divider  {
    border-top-color :@light-dark-color !important;
  }

  .ant-layout-header, .gx-header-horizontal {
    background: @black-color !important;
    color: @dark-theme-text-color;
  }

  .ant-table-thead > tr > th {
    border-bottom-width: 1px !important;
    border-bottom-color: @dark-theme-border-color !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom-width: 2px !important;
    border-bottom-color: @light-dark-color !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr.ant-table-row:focus > td,
  .ant-table-cell-row-hover {
    background-color: rgba(@white-color, 8%) !important;
}

  & h1, & h2, & h3, & h4, & h5, & h6,
  & .h1, & .h2, & .h3, & .h4, & .h5, & .h6,
  h1.ant-typography,
  .ant-select-selection-item,
  .ant-empty-description {
    color: @dark-theme-text-color !important;
  }

  svg {
    color: @white-color !important ;
  }
  .ant-form-item-control-input-content{
    .ant-input {
      color: @dark-theme-text-color;
      background-color: @light-dark-color !important;
      border: 1px solid @light-dark-color !important;
    }

    .custom-input {
      .ant-input {
        background-color: transparent !important;
        border: none !important;;
      }
    }
  }


  .ant-select:not(.ant-select-borderless) .ant-select-selector {
    background-color: @light-dark-color !important;
    border: 1px solid @light-dark-color !important;
  }

  .ant-form-vertical .ant-form-item-label > label {
    color: @dark-theme-text-color !important;
  }

  .user-dropdown-trigger {
    span {
      color: @dark-theme-text-color !important;
    }
  }

  .ant-dropdown, .ant-dropdown-menu, .ant-select-dropdown, .ant-select-item, .ant-modal-content {
    background-color: @black-color;
    color: @dark-theme-text-color !important;

    svg, a, span {
      color: @dark-theme-text-color;
    }
  }


  & th,
  & td,
  & .column-title,
  & .ant-table-column-title {
    color: @dark-theme-text-color !important;
    border-top-color: @dark-theme-border-color !important;

    svg, a, span {
      color: @dark-theme-text-color !important;
    }
  }

  .ant-select-selection-placeholder {
    color: @gray-color !important;
  }

  .custom-input {
    border-bottom: 1px solid @light-dark-color !important;
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) {
    .ant-checkbox-inner {
      background-color: transparent !important;
      border: 1px solid @light-dark-color !important;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner{
         background-color: @primary-color !important;
      }
    }
  }

  .custom-tag {
    color: @primary-color !important;
  }

  .ant-checkbox-wrapper-disabled {
    .ant-checkbox-inner {
      background-color: @dark-color !important;
      border: none;
    }
    .ant-checkbox-inner::after  {
      border-color: @white-color !important;
    }
  }
  .ant-table-column-sort  {
    background-color: transparent !important;
  }

  .user-dropdown .privacy-policies-terms-of-use div a {
    color: @dark-theme-text-color !important;
  }

  .user-dropdown .privacy-policies-terms-of-use div a:last-child::before {
    background:@dark-theme-text-color !important;
  }
}

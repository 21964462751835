@import '../../../../../../styles/variables.less';

.switch-renderer {
  .tag-active {
    background-color: @primary-color;
    color: #fff;
  }
  .tag-inactive {
    background-color: rgba(0, 0, 0, 0.25);
    color: #fff;
  }
}

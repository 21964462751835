@import '../../../styles/variables.less';

#customer-wrapper-component {
  .tabs {
    margin-bottom: 40px;

    a {
      font-size: 16px;
      padding-bottom: 8px;
      border-bottom: 2px solid transparent;
      font-weight: 500;

      & + a {
        margin-left: 32px;
      }
    }

    .active {
      // color: @text-color !important;
      border-bottom: 2px solid @primary-color !important;
    }
  }

  .timeline-button-wrapper {
    height: 50px;
  }

  .timeline-button {
    position: absolute;
    z-index: 50;
    bottom: 0;
    right: 0;
    margin-bottom: 0;
    border-radius: 0;
    border: 0;
    background: @nav-dark-bg;
    color: @white-color;
    width: 300px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    &:hover,
    &:focus {
      background: lighten(@nav-dark-bg, 5%);
      color: @white-color;
    }
  }

  .ant-table-content {
    svg {
      color: @black-color;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
    th.ant-table-cell {
      span.column-title {
        color: @text-color;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .ant-table-thead > tr > th:before {
    background-color: @gray-color !important;
  }

  .custom-tag {
    background-color: @light-primary-color;
    color: @primary-color;
    padding: 4px 16px !important;
  }

  .ant-switch-checked {
    background-color:  @primary-color;
  }

  .custom-page-title .title {
    font-size: 32px;
  }
  .custom-page-title .subtitle {
    font-size: 16px;
  }

  .ant-select-selection-item {
    font-size: 16px;
  }
  .ant-select-selection-placeholder {
    font-size: 16px;
  }

  .ant-table-column-sorter {
    display:none;
  }
  .ant-select-arrow {
    color: @text-color;
  }
}

@import "../../../styles/variables.less";

#login-component {
  display: flex;
  justify-content: space-between;

  .login {
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .loginGoogle {
      width: 341px;
      height: 70px;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 21px 36px;
      font-size: 21px;
      background: transparent;
    }
  }

  .login-img {
    img {
      width: 65vw;
      height: 100vh;
    }
  }
}

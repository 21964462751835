a, input, .ant-input  {
  transition: unset !important;
}
.gx-main-content-wrapper {
  padding-bottom: 32px;
}

.ant-select-item {
  color: @heading-color;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: @light-primary-color;
  color: @primary-color !important;;
  font-weight: 700;
}

.ant-select-item {
  &:hover {
    background-color: @light-primary-color;
    color: @primary-color !important;
  }
}

.ant-dropdown {
  padding: 4px 0;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;

  .ant-menu-vertical {
    border-right: none;
  }

  .ant-dropdown-menu-vertical {
    padding: 0;
    box-shadow: none;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }
  }

  .ant-dropdown-menu-item:not(.gx-text-danger) {
      svg {
      color: @black-color;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
  .ant-dropdown-menu-item.gx-text-danger{
    svg {
      color: @danger-color;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }

  .ant-menu-item,
  .ant-dropdown-menu-item {

    height: 36px;
    line-height: 36px;
    color: lighten(@text-color, 10%);

    &.ant-menu-item-disabled,
    .ant-menu-submenu-disabled,
    &.ant-dropdown-menu-item-disabled,
    .ant-dropdown-menu-submenu-disabled {
      color: rgba(0, 0, 0, 0.25) !important;
    }

    &.gx-text-danger {
      span {
        color: @danger-color !important;
        font-weight: 700;
        svg {
          color: @danger-color !important;
        }
      }
    }

    &:hover:not(.gx-text-danger) {
      background-color: rgba(@primary-color, 0.08);
      font-weight: 700;

      span {
        span:not(.dark-mode) {
          color: @primary-color !important;
        }
        a {
          color: @primary-color !important;
        }

      svg:not(.dark-mode) {
         color: @primary-color !important;
        }
      }

      &.ant-menu-item-disabled,
      .ant-menu-submenu-disabled,
      &.ant-dropdown-menu-item-disabled,
      .ant-dropdown-menu-submenu-disabled {
        background-color: transparent;
      }

    }

    &:hover {
      &.gx-text-danger {
        background-color: rgba(@danger-color, 0.08);

          span {
           color: @danger-color !important;

          svg {
            color: @danger-color !important;
          }
        }
      }
    }
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #000;
}

.ant-table-tbody > tr > td {
  border-bottom-width: 1px;
  border-bottom-color: @border-color;
}

.ant-table-thead > tr > th {
  font-weight: 300;
  color: lighten(@text-color, 10%);
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-color: @gray-color;
}

.ant-table {
  background-color: transparent;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: darken(@gray-light-color, 3%);
}

.ant-layout-footer {
  margin-top: 20px;
}

.ant-input,
.ant-input-number {
  border-radius: 8px;
  color: @text-color;
  border-color: @gray-light-color-1;
}

.ant-input-number-handler-wrap {
  border-radius: 0 8px 8px 0;
}

.ant-input-number {
  width: 100%;
  margin-right: 0;
}

.ant-input[disabled] {
  color: lighten(@text-color, 40%);
}

.ant-form-vertical {
  .ant-row {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;

    > [class*='ant-col-'] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .ant-form-item {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }

  .ant-form-item-label {
    padding-bottom: 6px;

    > label {
      font-size: 0.75rem;
      color: lighten(@text-color, 30%);
      font-weight: 300;
    }
  }
}

.ant-form-item-label > label.ant-form-item-required::before {
  display: none !important;
}

.ant-btn {
  margin-bottom: 0;
  font-weight: 500;

  &:not(.ant-btn-circle) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.form-inline-button {
  display: flex;
  align-items: flex-end;

  .ant-form-item {
    width: 100%;
  }
}

.ant-divider-horizontal {
  margin: 20px 0;
}

.ant-form-item-explain {
  font-size: 0.75rem;
  padding-top: 4px;
  padding-bottom: 4px;
}

.ant-form-item-extra {
  font-size: 0.75rem;
  font-weight: 300;
}

.ant-select-item-option-content,
.ant-select-selection-item {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    color: @black-color;
    width: 24px;
    height: 24px;
  }
}

.ant-tag {
  margin: 0;
  border-radius: 100px;
  border: none;
  font-weight: 400;
  line-height: 24px;
  padding: 0px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
}

.ant-form-item-with-help {
  margin-bottom: 0 !important;
}

.ant-message-custom-content {
  span {
    color: lighten(@text-color, 5%);
    font-weight: 500;
  }
}

.ant-divider {
  border-top-color: @border-color;
}

.ant-modal-confirm-body-wrapper {
  .ant-modal-confirm-btns {
    .ant-btn {
      border-radius: 100px;
    }
  }
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: inherit;
}

.ant-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-pagination-total-text {
    font-weight: 300;
  }

  .ant-pagination-item-ellipsis {
    font-size: 0.75rem;
  }
}

.custom-footer-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: unset;

  .go-to-form {
    display: inline;

    .new-page-input {
      width: 60px;
      margin: 0;
      display: inline-block;
    }

    .ant-btn-text {
      font-weight: normal;
    }
  }

  .ant-pagination {
    font-size: 0.9rem;
    width: unset;
    > li {
      margin-bottom: 0;
    }
  }
}

.ant-pagination-prev,
.ant-pagination-next {
  .ant-pagination-item-link {
    border-radius: 50%;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ant-pagination-disabled {
    .ant-pagination-item-link {
      opacity: 0.3;
    }
  }

  &:not(.ant-pagination-disabled) {
    &:focus {
      .ant-pagination-item-link {
        color: inherit;
      }
    }

    &:hover {
      .ant-pagination-item-link {
        background-color: lighten(@gray-light-color-1, 5%);
        color: @text-color;
      }
    }
  }
}

.ant-pagination-item {
  border-radius: 50%;
  background: transparent;
  border: none;
  font-family: @font-family;
  font-weight: 300;
  margin-right: 6px;

  a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ant-pagination-item-active {
    background-color: @primary-color;

    a {
      color: #fff;
      cursor: default;
    }

    &:hover,
    &:focus {
      a {
        color: #fff;
      }
    }
  }

  &:focus {
    a {
      color: inherit;
    }
  }

  &:hover:not(.ant-pagination-item-active) {
    background-color: lighten(@gray-light-color-1, 5%);

    a {
      color: @text-color;
    }
  }
}

.ant-input-affix-wrapper {
  padding: 0;
  overflow: hidden;

  input.ant-input {
    border-radius: 0;
    line-height: 34px;
    padding: 0 11px;

    &.ant-input-sm {
      line-height: 24px;
    }

    &.ant-input-lg {
      line-height: 38px;
      font-size: 0.9rem;
    }

    &:not(:last-child) {
      padding-right: 38px;
    }

    &:not(:first-child) {
      padding-left: 38px;
    }
  }

  i {
    color: @gray-color;
  }

  .ant-input-prefix {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    margin-left: 11px;
  }

  .ant-input-suffix {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    margin-right: 11px;
  }
}

.ant-collapse,
.ant-collapse-content,
.ant-collapse > .ant-collapse-item {
  border-color: @border-color;
}

.ant-picker-range {
  input {
    border-radius: 0;
    color: @text-color;
  }
}


.ant-popover-buttons button {
  margin-left: 0;
}

.ant-result-title {
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 5px;
}

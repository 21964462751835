@import '../../../../../../styles/variables.less';

#select-policies{

  .tag-perms {
    color: @primary-color;
    background-color: @inverse-primary-color;
  }

  .custom-collapse {
    padding: 16px;
    background: @white-color;
    border-radius: 8px !important;
    border: solid 1px @border-color;

    .custom-collapse-icons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      margin-top: 16px;
    }
  }

.ant-collapse-content-box {
    svg {
      color:  @black-color;
      width: 24px;
      height: 24px;
    }
  }

  .custom-collapse-items {
    svg {
      margin-right: 4px;
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-header-text {
    width: 100%;
  }

.ant-collapse-item-disabled {
  .header-text {
    svg {
      color:  @dark-gray-color;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
}

.ant-collapse-item:not(.ant-collapse-item-disabled) {
  .header-text {
    svg {
      color:  @black-color;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
}

  .header-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

   div {
      display: flex;
      align-items: center;
    }
  }

  .ant-collapse-content {
    color: @dark-gray-color;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
  }

  .divider-checkboxes {
    border-top: 1px solid @border-color;
    width: 95%;

    &:nth-child(-n+2) {
      border-top: none;
    }
  }

  .divider-crud {
    margin-top: 8px;
    .col-read-tooltip {
      display: flex;
      align-items: center;
    }
    .ant-checkbox-inner {
      width: 19px;
      height: 19px;
      border-radius: 50%;
    }

    .policies-checkboxes {
      display:flex;
      gap: 21px;
      margin-right: 1px;
    }
  }
}

.ant-tooltip-inner {
  text-align: center !important;
}

.custom-panels {
  .ant-collapse {
     margin-bottom: 32px;
  }
}

@import '../../styles/variables.less';

#user-info-component {
  display: flex;
  align-items: center;
  font-size: 1rem;

  .user-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    cursor: pointer;

    span {
      color: @dark-gray-color;
      font-size: 1rem;
      letter-spacing: 0.6px;
      margin-right: 16px;
    }

    svg {
      color: @gray-light-color-1;
    }

    .custom-caf-icon {
      color: @gray-light-color-1;
      margin-left: 4px;
      opacity: 0.8;
    }

    .ant-avatar {
      width: 32px !important;
      height: 32px !important;
    }

  }
}

.user-dropdown {
  min-width: 266px !important;
  width: 266px !important;
  top: 50px !important;

  padding-top: 8px 0;

  .user-profile {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 14px 20px 0 20px;

    > div {
      position: relative;

      > i {
        position: absolute;
        bottom: 2px;
        right: 4px;
        font-size: 0.875rem;
        background: #eeeeee;
        padding: 5px;
        border-radius: 50%;
      }
    }

    section {
      h3 {
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
        margin-bottom: 5px;
      }

      span {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .user-menu {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ant-dropdown-menu-item:hover  {
      background-color: transparent;
    }

    .dark-mode {
      cursor: default;
    }

    span {
      font-weight: 700;
    }
    .ant-dropdown-menu-title-content {
      justify-content: space-between !important;
      gap: 53px;
    }

    .custom-menu {
      color: @black-color;
      font-size: 1rem;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: @primary-color;
      }
    }

    .btn-logout {
      margin-top: 24px;
      min-width: 234px;
    }
  }

  .nav-link-users-container {
    height: auto !important;
    padding: 6px 10px !important;
  }

  .nav-link-users {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 10px;

    span {
      color: @text-color;
      font-size: 0.9rem;
      font-weight: 500;
    }

    i {
      margin-right: 12px;
      color: @dark-gray-color;
      font-size: 0.9rem;
    }
  }

  .ant-divider {
    border-top-color: @border-color;
  }

  .privacy-policies-terms-of-use {
    padding: 14px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        font-size: 0.5rem;
        color: @text-color;

        &:hover {
          color: @primary-color;
        }

        &:last-child {
          padding-left: 15px;
          margin-bottom: 2px;
          position: relative;
        }

        &:last-child::before {
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: @text-color;
          position: absolute;
          left: 0;
          top: 50%;
          margin-left: 8px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}

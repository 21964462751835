@import '../../../styles/variables.less';

#unauthorized-component {
  #unauthorized-content {
    width: 100%;
    height: 100%;
    padding: 0 12%;

    .title {
      text-align: center;
      color: @black-color;
      opacity: 0.75;
      font-size: 34px;
    }

    .subtitle {
      margin: 0;
      color: @black-color;
      opacity: 0.75;
    }

    img {
      margin-top: 40px;
      width: 368px;
      height: 304px;
    }
  }
}

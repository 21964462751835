@import '../../styles/variables.less';

#header-component {
  &.gx-header-horizontal {
    &::before {
      display: none !important;
    }
  }

  &.gx-inside-header-horizontal {
    .ant-menu-item {
      padding: 0 10px;
    }
  }

  .gx-container {
    max-width: 1280px;
  }

  .gx-logo {
    margin-right: 45px;
  }

  .gx-header-notifications {
    padding: 0 10px;
  }

  .gx-header-notifications li:first-of-type {
    margin-left: 0;
  }

  .nav-link {
    padding: 3px;
    border-bottom: 3px solid transparent;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.6px;

    &:hover {
      opacity: 1;
    }

    &:before {
      bottom: -2px;
    }

    &.active {
      border-bottom: 3px solid @menu-active-color;
      opacity: 1;
    }
  }

  header {
    border-bottom: 1px solid @border-color;
    top: 0;
    max-height: 200px;

    a:hover {
      color: @text-color;
    }
  }

    &-nav {
    background-color: @white-color;
    color: @text-color;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    margin: 0;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after  {
    border: none
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item::after  {
    border: none
  }

  .logo-img{
    width: 102px;
    height: 42px;
  }

  .logo-other-domain {
    height: 32px !important;
  }
}
